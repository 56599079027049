body {
  background-color: black;
  margin: 0;
  font-family: "Chakra Petch", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glitch-effect {
  color: white;
  font-style: italic;
  font-size: 36px;
  font-weight: bold;
  position: absolute;
  font-family: "Chakra Petch", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  animation: glitch 1s linear infinite;
}

@keyframes noise-anim {
  0% {
    clip: rect(58px, 9999px, 91px, 0);
  }
  5% {
    clip: rect(58px, 9999px, 8px, 0);
  }
  10% {
    clip: rect(55px, 9999px, 28px, 0);
  }
  15% {
    clip: rect(88px, 9999px, 89px, 0);
  }
  20% {
    clip: rect(4px, 9999px, 10px, 0);
  }
  25% {
    clip: rect(3px, 9999px, 54px, 0);
  }
  30% {
    clip: rect(97px, 9999px, 60px, 0);
  }
  35% {
    clip: rect(23px, 9999px, 30px, 0);
  }
  40% {
    clip: rect(79px, 9999px, 13px, 0);
  }
  45% {
    clip: rect(77px, 9999px, 68px, 0);
  }
  50% {
    clip: rect(66px, 9999px, 48px, 0);
  }
  55% {
    clip: rect(20px, 9999px, 56px, 0);
  }
  60% {
    clip: rect(100px, 9999px, 9px, 0);
  }
  65% {
    clip: rect(28px, 9999px, 56px, 0);
  }
  70% {
    clip: rect(43px, 9999px, 79px, 0);
  }
  75% {
    clip: rect(6px, 9999px, 37px, 0);
  }
  80% {
    clip: rect(94px, 9999px, 30px, 0);
  }
  85% {
    clip: rect(100px, 9999px, 36px, 0);
  }
  90% {
    clip: rect(78px, 9999px, 67px, 0);
  }
  95% {
    clip: rect(24px, 9999px, 56px, 0);
  }
  100% {
    clip: rect(90px, 9999px, 84px, 0);
  }
}

.glitch-effect:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  word-break: break-word;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(38px, 9999px, 56px, 0);
  }
  5% {
    clip: rect(63px, 9999px, 80px, 0);
  }
  10% {
    clip: rect(74px, 9999px, 33px, 0);
  }
  15% {
    clip: rect(78px, 9999px, 10px, 0);
  }
  20% {
    clip: rect(95px, 9999px, 22px, 0);
  }
  25% {
    clip: rect(80px, 9999px, 15px, 0);
  }
  30% {
    clip: rect(42px, 9999px, 15px, 0);
  }
  35% {
    clip: rect(35px, 9999px, 51px, 0);
  }
  40% {
    clip: rect(28px, 9999px, 95px, 0);
  }
  45% {
    clip: rect(59px, 9999px, 53px, 0);
  }
  50% {
    clip: rect(21px, 9999px, 82px, 0);
  }
  55% {
    clip: rect(54px, 9999px, 2px, 0);
  }
  60% {
    clip: rect(6px, 9999px, 27px, 0);
  }
  65% {
    clip: rect(84px, 9999px, 18px, 0);
  }
  70% {
    clip: rect(94px, 9999px, 46px, 0);
  }
  75% {
    clip: rect(43px, 9999px, 10px, 0);
  }
  80% {
    clip: rect(32px, 9999px, 52px, 0);
  }
  85% {
    clip: rect(55px, 9999px, 21px, 0);
  }
  90% {
    clip: rect(38px, 9999px, 30px, 0);
  }
  95% {
    clip: rect(90px, 9999px, 18px, 0);
  }
  100% {
    clip: rect(38px, 9999px, 49px, 0);
  }
}

.glitch-effect:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  word-break: break-word;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}
